import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    categories: {},
    products: {}
}

const getters = {
    getStates(state, getters, rootState) {
        return state.states
    },
    getCategories: (state, getters, rootState) => {
        return state.categories
    },
    getProducts: (state, getters, rootState) => {
        return state.products
    },
    getSortedCategories: (state, getters, rootState) => {
        return Object.values(state.categories).sort((a, b) => parseInt(a.order) - parseInt(b.order))
    },
    getSelectedCategory: (state, getters, rootState) => {
        for (var i in state.categories) {
            if (state.categories[i].selected) return state.categories[i]
        }
        return false
    }

}

const actions = {
    loadData(context, params) {
        var data = {
            'products': {
                '1': {
                    id: '1',
                    name: 'Whopper',
                    category: '1',
                    image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png',
                    active: true,
                    forecast: [false, false, { 'v': 2, 'i': 1 }, false, false, { 'v': 4, 'i': 2 }, false, { 'v': 1, 'i': 0 }, false, { 'v': 2, 'i': 1 }, { 'v': 2, 'i': 0 }, { 'v': 1, 'i': 0 }, { 'v': 3, 'i': 1 }],
                    summary: 12
                },
                '2': {
                    id: '2',
                    name: 'Burger',
                    category: '1',
                    image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png',
                    active: true,
                    forecast: [false, false, { 'v': 2, 'i': 1 }, false, false, { 'v': 2, 'i': 0 }, false, { 'v': 3, 'i': 2 }, false, { 'v': 2, 'i': 0 }, { 'v': 2, 'i': 0 }, { 'v': 2, 'i': 0 }, { 'v': 4, 'i': 2 }],
                    summary: 16
                },
                '3': {
                    id: '3',
                    name: 'Crispy Chicken',
                    category: '2',
                    image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png',
                    active: true,
                    forecast: [false, { 'v': 2, 'i': -1 }, false, false, false, { 'v': 4, 'i': 2 }, false, { 'v': 2, 'i': -1 }, false, { 'v': 3, 'i': 2 }, { 'v': 2, 'i': 0 }, false, { 'v': 3, 'i': 1 }],
                    summary: 8
                }
            },
            'categories': {
                '1': { id: '1', name: 'Cocina', color: '#ff0000', selected: false },
                '2': { id: '2', name: 'Limpieza', color: '#00ff00', selected: false }
            }
        }

        context.commit('setCategories', data.categories)
        return context.commit('setProducts', data.products)
    }

}

const mutations = {
    setStates(state, data) {
        state.states = data
    },
    setCategories(state, data) {
        state.categories = data
    },
    setProducts(state, data) {
        state.products = data
    },
    selectCategory(state, category) {
        if (category == false || category == 'false') {
            for (var k in state.categories) {
                var cat = state.categories[k]
                cat.selected = false
            }
        } else {
            for (var k in state.categories) {
                var cat = state.categories[k]
                if (cat.id == category.id) {
                    cat.selected = true
                } else {
                    cat.selected = false
                }
            }
        }
        Vue.set(state, 'categories', { ...state.categories })
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
