<template>
    <div v-if="show" class="popup-container" :class="[{ blocking: blocking }, customClass, $mq, mood, { 'email-alert': type === 'emailAlert' }, { tablePopup: type === 'tableColumn' }, { renew: type === 'renew' }, { 'item-settings': type === 'itemSettings' }]" @click.self="cancel">
        <div class="popup" v-bind:class="type">
            <!-- bind class popup.clases AND TYPE -->
            <div class="pop-alex" :class="[{ error: showingError }, mood]" v-if="!disabledAlexIcon.includes(type)"></div>
            <div v-if="type == 'select'" class="pop-alex-lottie">
                <LottieAnimation v-if="type == 'select'" :timeToRepeat="10000" :loopAnimation="true" :animationName="'andy.json'"></LottieAnimation>
            </div>

            <!-- <div class="pop-header" v-if="type != 'media' && type != 'library'" >
                <p v-if="title" class="title-message">{{title}}</p>
             </div> -->

            <div class="pop-header" v-if="type === 'action-plan-loss' || type === 'action-plan-create'">
                <p v-if="title" class="title">{{ title }}</p>
            </div>

            <div class="pop-body">
                <!-- media -->
                <div class="kind-of preview hide-scrollbar" v-if="type == 'media' || type == 'previewSignature'">
                    <div v-if="image">
                        <img v-if="typeof image === 'string'" :src="image" style="object-fit: contain; width: 100%; height: 100%" />
                        <img v-else v-for="(img, key) in image" :src="img" :key="key" style="object-fit: contain; width: 100%; height: 100%" />
                    </div>
                    <p v-else class="empty"></p>
                </div>

                <!-- tableColumn -->
                <h1 v-if="type == 'tableColumn'">{{ title }}</h1>
                <div class="kind-of check-container" v-if="type == 'tableColumn'">
                    <!-- <div v-if="header.text != ''" v-for="(header, index) in $data.headers" @click="checkHeader(header)" :class="{ activeHeader: header.visible === true }" :key="index" class="check-box">
                        <div class="check-input"></div>
                        <p class="check-title">{{ header.text }}</p>
                    </div> -->
                    <draggable v-model="columnHeaders" @start="drag = true" @end="drag = false">
                        <div v-if="header.text != ''" v-for="header in columnHeaders" :key="header.id" @click="checkHeader(header)" class="check-box-container">
                            <div :class="{ activeHeader: header.visible === true }" class="check-box">
                                <div class="check-input"></div>
                                <p class="check-title">{{ header.text }}</p>
                            </div>
                        </div>
                    </draggable>
                </div>

                <!-- Select, -->
                <div class="kind-of" v-else-if="type == 'select'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>
                    <SearchBar :search="search" @on-search="searchBarInput" @on-button-click="cleanSearch" :placeholder="getString('popup.audits.search_placeholder')" />
                    <ul v-if="Object.keys(list).length > 0" class="list select-list">
                        <li v-for="(item, key) in filteredList" :key="key" :class="{ item: true, default: item.default, selected: selectedEl === item.id }" :data-id="item.id" @click="selectMe(item.id, key)">
                            <span class="name" v-if="item.name">{{ item.name }}</span>
                        </li>
                    </ul>
                    <p v-else class="empty" v-html="title"></p>
                </div>

                <!-- Select double -->
                <div class="kind-of" v-else-if="type == 'selectDouble'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>

                    <div class="left hide-scrollbar">
                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <li v-for="item in list" class="item user" :class="{ selected: selectedEl == item.id }" @click="selectMe(item.id)">
                                <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /> "></span>
                                <span class="user-name">{{ item.name }} {{ item.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>

                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <ul v-if="Object.keys(listSec).length > 0" class="list select-list hide-scrollbar" :data-msg="disabledMessage">
                            <li v-for="item in listSec" v-if="item.default" class="item default" :class="[{ selected: selectedSecEl == item.id }]" :id="item.id" @click="selectMeSec(item.id)">
                                <span class="name">{{ item.name }}</span>
                            </li>
                            <li v-for="item in listSec" v-if="!item.default" class="item" :class="[{ selected: selectedSecEl == item.id }]" :id="item.id" @click="selectMeSec(item.id)">
                                <span class="name">{{ item.name }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(listSec).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>
                </div>

                <!-- numeric -->
                <Numpad class="kind-of" v-else-if="type == 'numeric'" @sendValue="changeValue" @saveAndClose="save" :givenValue="inputVal" :decimal="decimal" :hasNegative="hasNegative" :pinType="'numeric'"></Numpad>

                <!-- temperature -->
                <PopupTemperature class="hide-scrollbar" v-else-if="type == 'temperature'" @sendValue="changeValue" @saveAndClose="save" :givenValue="inputVal" :stepNum="tempOptions.stepNum" :numDec="tempOptions.numDec" :stepDec="tempOptions.stepDec" :mesure="tempOptions.mesure" :sensorType="tempOptions.sensorType" :hasNegative="true" @updateSensorStep="tempUpdateSensorStep"></PopupTemperature>

                <!-- Ticket -->
                <PopupTicket class="kind-of" v-else-if="type == 'ticket'" @sendValue="changeValue" @saveAndClose="save" ref="popupTicket"></PopupTicket>

                <!-- DateTime -->
                <PopupDateTime class="kind-of" v-else-if="type == 'dateTime'" :showingError="showingError" :messageError="messageError" :date="date" :time="time" :only_past="only_past" @sendValue="changeValue" @saveAndClose="save" ref="dateTime"> </PopupDateTime>

                <!-- Geolocation -->
                <PopupGeolocation v-else-if="type == 'geolocation'" class="geolocation" ref="popupGeolocation"> </PopupGeolocation>

                <!-- Resource -->
                <PopupResource v-else-if="type == 'resource'" class="resource" :resource="resource" ref="popupResource" @close="cancel('cross')"> </PopupResource>

                <!-- quantity -->
                <div class="kind-of" v-else-if="type == 'quantity'">
                    <div class="left hide-scrollbar">
                        <p class="message">{{ message }}</p>
                        <h6>{{ productName }}</h6>

                        <!-- <button v-if="inputVal > 0" class="act rem-amount" @click="removeAll">
                      {{ getString('popup.quantity.remove_all') }}
                    </button> -->
                    </div>

                    <div class="right hide-scrollbar">
                        <Numpad @sendValue="changeValue" :decimal="decimal" :givenValue="inputVal" :max="100"></Numpad>
                    </div>
                </div>

                <!-- timeClock -->
                <div class="kind-of" v-else-if="type == 'timeclock'">
                    <div class="left hide-scrollbar">
                        <div class="place">
                            <div class="item" :class="selectedTab == 'all' ? 'selected' : ''" @click="selectTab('all')" data-val="all">Todos</div>
                            <div class="item" :class="selectedTab == 'in' ? 'selected' : ''" @click="selectTab('in')" data-val="in">En Local</div>
                            <div class="item" :class="selectedTab == 'out' ? 'selected' : ''" @click="selectTab('out')" data-val="out">Fuera</div>
                        </div>
                        <p v-show="showingError" class="message-error" v-html="messageError"></p>

                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <li v-for="item in list" class="item user" :class="[item.clockClass ? item.clockClass : '', selectedEl == item.id ? 'selected' : '']" v-bind:data-id="item.id" v-if="selectedTab == 'all' || (item.clockClass == 'clocked-in' && selectedTab == 'in') || (item.clockClass == 'clocked-out' && selectedTab == 'out')" @click="selectMe(item.id)">
                                <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /></span>
                                <span class="user-name">{{ item.name }} {{ list.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>

                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <Numpad @sendValue="changeValue" :pinType="'password'" :givenValue="inputVal" :disabled="disNumpad"></Numpad>
                    </div>
                </div>

                <!-- login, access -->
                <div class="kind-of" v-else-if="type == 'login' || type == 'access'">
                    <template v-if="Object.keys(list).length > 0">
                        <div class="left hide-scrollbar">
                            <p v-show="message" class="message-info" v-html="message"></p>
                            <p v-show="showingError" class="message-error" v-html="messageError"></p>

                            <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                                <li v-for="item in list" class="item user" :class="{ selected: selectedEl == item.id }" v-bind:data-id="item.id" @click="selectMe(item.id)">
                                    <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /></span>
                                    <span class="user-name">{{ item.name }} {{ item.surname }}</span>
                                </li>
                            </ul>
                            <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                        </div>

                        <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                            <Numpad @sendValue="changeValue" v-bind:pinType="'password'" v-bind:givenValue="inputVal" v-bind:disabled="disNumpad"></Numpad>
                        </div>
                    </template>

                    <template v-else>
                        <p class="message">{{ getString('tooltip.error_logout') }}</p>
                    </template>
                </div>

                <!-- Detalle de libreria -->
                <LibraryDetail class="kind-of" v-else-if="type == 'library'" :file="file"></LibraryDetail>

                <!-- Seleccionable fecha -->
                <div class="kind-of" v-else-if="type == 'date'">
                    <!-- :allowed-dates="allowedDates" -->

                    <functional-calendar v-model="inputVal" :dayNames="getStringObject('calendar.day_names')" :monthNames="getStringObject('calendar.months')" :shortMonthNames="getStringObject('calendar.months')" :isDatePicker="true" :change-month-function="true" :date-format="'yyyy/mm/dd'" :change-year-function="true" :limits="{ min: '', max: '' }"></functional-calendar>
                    <!-- <v-date-picker
                        v-model="inputVal"
                        :locale="aLocale"
                        landscape
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->
                </div>

                <!-- Seleccionable fecha RANGO -->
                <div class="kind-of hide-scrollbar" v-else-if="type == 'dateRange'">
                    <!-- :allowed-dates="allowedDates" -->

                    <!-- :is-multiple="true"
                                    :calendars-count="2" -->
                    <functional-calendar v-model="dateRange" :dayNames="getStringObject('calendar.day_names')" :monthNames="getStringObject('calendar.months')" :shortMonthNames="getStringObject('calendar.months')" :isDatePicker="true" :key="updatedDayCalendarTwo" @input="dayClickedCalendarOne" :change-month-function="true" :date-format="'yyyy/mm/dd'" :change-year-function="true" :limits="{ min: '', max: dateRangeTwo.selectedDate ? dateRangeTwo.selectedDate : dateEnd }"></functional-calendar>
                    <!-- :limits="{min: '', max: dateRangeTwo.selectedDate ? dateRangeTwo.selectedDate : dateEnd}" -->

                    <!-- <v-date-picker
                        v-model="dateRange[0]"
                        :locale="aLocale"
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->

                    <functional-calendar :key="updatedDayCalendarOne" v-model="dateRangeTwo" :dayNames="getStringObject('calendar.day_names')" :monthNames="getStringObject('calendar.months')" :shortMonthNames="getStringObject('calendar.months')" :isDatePicker="true" :change-month-function="true" :change-year-function="true" :date-format="'yyyy/mm/dd'" :limits="{ min: dateRangeFirstCalendar.selectedDate ? dateRangeFirstCalendar.selectedDate : '', max: dateEnd }"></functional-calendar>
                    <!-- @input="dayClickedCalendarTwo" -->

                    <!-- <v-date-picker
                        v-model="dateRange[1]"
                        :locale="aLocale"
                        reactive
                        :min="dateRange[0]"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->

                    <!-- <div class="fcalendar-container">
                  <functional-calendar
                    v-model="dateRange[0]"
                    @input="setRangeResult(dateRange[0])"
                    :is-multiple="true"
                    :is-date-range="true"
                    :calendars-count="2"
                    :isDatePicker="true"
                    :change-month-function="true"
                    :change-year-function="true"
                    :markedDates="[{date: dateEnd, class: 'expiration'},{date: dateRange[0], class: 'vfc-marked'}]"
                    :limits="{min: dateStart, max: ''}"
                  ></functional-calendar>
                </div> -->
                </div>

                <!-- Input -->
                <div class="kind-of" v-else-if="type == 'input'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>
                    <input type="text" v-model="inputVal" required />
                </div>

                <!-- SIGNATURE (SIGN)-->
                <div class="kind-of" v-else-if="type == 'sign'">
                    <div class="left hide-scrollbar">
                        <p v-show="message" class="message-info" v-html="message"></p>
                        <p v-show="showingError" class="message-error" v-html="messageError"></p>

                        <ul v-if="orderedEmployeesList" class="list users-list hide-scrollbar">
                            <!-- TODO REVIEW EL IF -->
                            <li v-for="employee in orderedEmployeesList" v-if="employee.conf.sign" class="item user" :class="{ selected: selectedEl == employee.id }" v-bind:data-id="employee.id" @click="selectMe(employee.id)">
                                <span class="user-color"><img class="main-img" v-bind:src="employee.avatar" /></span>
                                <span class="user-name">{{ employee.name }} {{ employee.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>
                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <div class="toolbar">
                            <div flat class="btn undo" @click="signUndo">
                                <div class="andy-icon undo"></div>
                                {{ getString('popup.sign.text_undo') }}
                            </div>
                            <div flat class="btn clear" @click="signClear">
                                <div class="andy-icon cancel"></div>
                                {{ getString('popup.sign.text_clear') }}
                            </div>
                        </div>
                        <vueSignature class="signature" ref="ref_sign" :sigOption="signOptions" :data-msg="disabledMessage" :w="['portrait'].includes($mq) ? '300px' : '600px'" :h="'300px'"></vueSignature>
                    </div>
                </div>

                <!-- Free Signature -->
                <div class="kind-of" v-else-if="type == 'freeSign'">
                    <div class="toolbar">
                        <div flat class="btn undo" @click="signUndo">
                            <div class="andy-icon undo"></div>
                            {{ getString('popup.sign.text_undo') }}
                        </div>
                        <div flat class="btn clear" @click="signClear">
                            <div class="andy-icon cancel"></div>
                            {{ getString('popup.sign.text_clear') }}
                        </div>
                    </div>
                    <vueSignature class="signature" ref="ref_sign" :sigOption="signOptions" :data-msg="disabledMessage" :w="['portrait'].includes($mq) ? '300px' : '600px'" :h="'300px'"></vueSignature>
                </div>

                <!-- Alerta de Incidencia (en tareas) -->
                <div class="kind-of" v-else-if="type == 'issueAlert'">
                    <!-- <p class="alert-title">{{ getString('popup.issue_alert.alert_message') }}</p> -->
                    <div class="message" v-html="message"></div>
                    <div class="suggest" v-if="selectedEl && !selected">
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_issue') }}</p>
                        <p class="issue-name" v-if="list && list[selectedEl] && list[selectedEl].name">{{ list[selectedEl].name }}</p>
                    </div>
                    <div class="suggest" v-else-if="!selectedEl && !selected">
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_new') }}</p>
                    </div>
                </div>

                <!-- Proceso -->
                <div class="kind-of" v-else-if="type == 'process'">
                    <div class="product-info">
                        <span class="product-title">{{ productName }}</span>
                    </div>

                    <div class="resume hide-scrollbar">
                        <div v-for="(pType, i) in processTypes" @click="changeStep(i)" class="resume-step" :class="[data[i].result ? 'done' : 'undone', { current: currentStep == i }]">
                            <h6 class="title">{{ data[i].title }}</h6>
                            <span class="subtitle">{{ data[i].result ? data[i].result : '' }}</span>
                        </div>
                    </div>

                    <div class="content">
                        <div v-for="(pType, i) in processTypes" class="step hide-scrollbar" :class="[data[i].result ? 'done' : 'undone', { current: currentStep == i }]">
                            <label v-if="typeof data[i].message != 'undefined'" class="step-message" v-html="data[i].message"></label>

                            <div v-if="pType == 'state'" class="tags" :class="'p-' + pType">
                                <span v-for="tag in data[i].tags" class="tag" :key="tag.id" :class="{ selected: data[i].result == tag.name }" @click="setProcessResult(pType, i, tag)">
                                    {{ tag.name }}
                                </span>
                            </div>

                            <div v-else-if="pType == 'date'" class="fcalendar-container" :class="'p-' + pType">
                                <functional-calendar
                                    v-model="data[i].input"
                                    @input="setProcessResult(pType, i, data[i].input)"
                                    :is-multiple="true"
                                    :calendars-count="2"
                                    :isDatePicker="true"
                                    :change-month-function="true"
                                    :change-year-function="true"
                                    :markedDates="[
                                        { date: data[i].maxDate, class: 'expiration' },
                                        { date: data[i].result, class: 'vfc-marked' }
                                    ]"
                                    :limits="{ min: data[i].minDate, max: '' }"
                                ></functional-calendar>
                            </div>

                            <div v-else-if="pType == 'datetime'" class="fcalendar-container" :class="'p-' + pType">
                                <div class="calendar">
                                    <functional-calendar
                                        v-model="data[i].date"
                                        @input="setProcessResult(pType, i, data[i].date)"
                                        :is-multiple="true"
                                        :calendars-count="1"
                                        :isDatePicker="true"
                                        :change-month-function="true"
                                        :change-year-function="true"
                                        :markedDates="[
                                            { date: data[i].maxDate, class: 'expiration' },
                                            { date: data[i].result, class: 'vfc-marked' }
                                        ]"
                                        :limits="{ min: data[i].minDate, max: '' }"
                                    ></functional-calendar>
                                </div>
                                <div class="time">
                                    <span class="message">{{ getString('label.popup.process.pick_time') }}</span>
                                    <input type="time" v-model="data[i].time" @input="setProcessResult(pType, i, data[i].time)" />
                                </div>
                            </div>

                            <div v-else-if="pType == 'string'" class="input-text" :class="'p-' + pType">
                                <input type="text" v-model="data[i].result" v-focus="currentStep == i" required @change="setProcessResult(pType, i, data[i].result)" />
                            </div>

                            <div v-else-if="pType == 'number'" class="input-number" :class="'p-' + pType">
                                <Numpad @sendValue="setProcessValue(pType, i, $event)" :givenValue="data[i].result" :max="data[i].max ? data[i].max : null"></Numpad>
                            </div>

                            <div v-else-if="pType == 'quantity'" class="input-number" :class="'p-' + pType">
                                <Numpad @sendValue="setProcessValue(pType, i, $event)" :givenValue="data[i].result" :max="data[i].max ? data[i].max : null"></Numpad>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Postponer (en tareas) -->
                <div class="kind-of" v-else-if="type == 'postpone'">
                    <div v-if="!showingError" class="postpone-info">
                        <p class="title-date">
                            {{ data.selectedDate == postponeTomorrow ? getString('answer.postpone.pretext_tomorrow') : getStringVariable('answer.postpone.pretext', momentFormat(data.selectedDate.selectedDate)) }}
                        </p>

                        <div class="step-date">
                            <!-- <v-date-picker
                                class="datepicker"
                                v-model="data.selectedDate"
                                :locale="aLocale"
                                landscape
                                reactive
                                :min="dateStart"
                                :max="dateEnd"
                                first-day-of-week="1"
                            ></v-date-picker> -->
                            <functional-calendar v-model="data.selectedDate" :dayNames="getStringObject('calendar.day_names')" :monthNames="getStringObject('calendar.months')" :shortMonthNames="getStringObject('calendar.months')" :isDatePicker="true" :change-month-function="true" :date-format="'yyyy-mm-dd'" :change-year-function="true" :limits="{ min: dateStart, max: dateEnd }"></functional-calendar>
                        </div>

                        <div class="step">
                            <p class="title">
                                <span v-if="data.showErrorReason" class="error">{{ data.errorReason }}</span>
                                {{ getString('answer.postpone.what_reason') }}
                            </p>
                            <ul class="reasons">
                                <li
                                    class="item"
                                    :class="{ selected: selected === item.id }"
                                    v-for="item in list"
                                    @click="
                                        selected = item.id
                                        data.showReason = item.id == '0' ? true : false
                                    "
                                >
                                    {{ item.name }}
                                </li>
                            </ul>
                            <p v-if="data.showReason" class="title">{{ getString('answer.postpone.explain_reason') }}</p>
                            <textarea v-if="data.showReason" class="reason_message" v-model="message"></textarea>
                        </div>
                    </div>
                    <div v-else class="postpone-error empty">{{ messageError }}</div>
                </div>

                <!-- Justificar (en tareas) -->
                <div class="kind-of" v-else-if="type == 'justify'">
                    <div v-if="!showingError" class="justify-info">
                        <div class="step">
                            <p class="title">
                                {{ getString('answer.justify.what_reason') }}
                            </p>
                            <ul class="reasons">
                                <li class="item" :class="{ selected: selected === item.id }" v-for="item in list" @click="selected = item.id">
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>

                        <div class="step">
                            <p class="title">{{ getString('answer.justify.explain_reason') }}</p>
                            <span v-if="data.showErrorReason" class="error"
                                ><p>{{ data.errorReason }}</p></span
                            >
                            <textarea class="reason_message" v-model="message"></textarea>
                        </div>
                    </div>
                    <div v-else class="justify-error empty">{{ messageError }}</div>
                </div>

                <!-- Release -->
                <div class="kind-of" v-else-if="type == 'release'">
                    <PopupRelease :data="data"></PopupRelease>
                </div>

                <!-- RENEW -->
                <div class="kind-of" v-else-if="type == 'renew'" :class="$mq">
                    <p class="title" v-if="title" v-html="title"></p>
                    <p class="message" v-if="message" v-html="message"></p>
                </div>

                <!-- LIST AUDITS -->
                <div class="kind-of" v-else-if="type == 'list_audits'">
                    <template v-if="Object.keys(list).length > 0">
                        <div class="full hide-scrollbar">
                            <p v-show="message" class="message-info" v-html="message"></p>
                            <p v-show="showingError" class="message-error" v-html="messageError"></p>

                            <SearchBar :search="search" @on-search="searchBarInput" @on-button-click="cleanSearch" :placeholder="getString('popup.audits.search_placeholder')" />
                            <ul v-if="Object.keys(list).length > 0" class="list audit-list">
                                <li
                                    v-for="(item, key) in filteredList"
                                    :key="key"
                                    class="item audit"
                                    :class="{
                                        selected: selectedEl == item.id,
                                        supervisor: typeof item.config.level === 'undefined' || item.config.level > level
                                    }"
                                    v-bind:data-id="item.id"
                                    @click="selectMe(item.id, key)"
                                >
                                    <span class="audit">{{ item.name }}</span>
                                </li>
                            </ul>
                            <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                        </div>
                    </template>

                    <template v-else>
                        <p class="message">{{ getString('tooltip.error_logout') }}</p>
                    </template>
                </div>

                <!-- Email alert -->
                <div class="kind-of" v-else-if="type == 'emailAlert'">
                    <p class="message" :class="type" v-html="message"></p>
                </div>

                <!-- Item settings  -->
                <div class="kind-of item-settings" v-else-if="type == 'itemSettings'">
                    <div class="title">
                        {{ title }}
                    </div>
                    <div v-if="itemSettings">
                        <div class="form-container item-settings">
                            <div class="form-content">
                                <div class="field-form in-line" v-for="item in Object.values(itemSettings)">
                                    <label class="switch">
                                        <input type="checkbox" v-model="item.value" @click="item.value = !item.value" />
                                        <div class="slider round" :class="[$mq]"></div>
                                    </label>
                                    <div class="label-alarm">
                                        {{ item.label }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="kind-of" v-else-if="type == 'action-plan-create'">
                    <p v-if="tplName" class="message">
                        <span>{{ getString('popup.action_plan_create.message') }}</span>
                        <span>
                            <b> {{ tplName }}</b>
                        </span>
                    </p>
                    <p v-else class="message">{{ getString('popup.action_plan_create.message2') }}.</p>
                </div>

                <!-- basico, confirmUpload, alert, delete... -->
                <div class="kind-of" v-else>
                    <p class="message" v-if="message" v-html="message"></p>
                    <span class="audit-score" v-if="customClass.toString().includes('auditPoints')">{{ data }}%</span>
                </div>
            </div>

            <div class="pop-footer" v-if="type != 'resource'">
                <div v-if="type == 'process'" class="buttons">
                    <button @click="changeStep(currentStep - 1)" v-if="currentStep > 0" class="act prev" :class="classPrev" v-html="textPrev"></button>
                    <button @click="cancel('button')" class="act cancel logout" :class="classCancel" v-html="textCancel"></button>
                    <button @click="save" v-if="currentStep == data.length - 1" class="act save" :class="classSave" v-html="textSave"></button>
                    <button @click="changeStep(currentStep + 1)" v-if="currentStep < data.length - 1" class="act next" :class="classNext" v-html="textNext"></button>
                </div>
                <div v-else-if="type == 'issueAlert'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel" v-html="textCancel"></button>
                    <button @click="save" class="act" :class="classSave" v-html="textSave"></button>
                    <button @click="saveStart" class="act save start" :class="[classSaveStart, { full: !selected && showSaveStart }]" v-html="textSaveStart" v-if="!selected && showSaveStart"></button>
                </div>
                <div v-else-if="type == 'renew'" class="buttons">
                    <button @click="cancel('button')" class="act save" v-html="textSave"></button>
                </div>
                <div v-else-if="type == 'offlineAlert'" class="buttons">
                    <button @click="cancel('button')" class="act save" v-html="textSave"></button>
                </div>
                <div v-else-if="type == 'login' && Object.keys(list).length == 0" class="buttons">
                    <button @click="logoutStore" class="act save">{{ getString('popup.login.text_logout') }}</button>
                </div>
                <div v-else-if="type == 'list_audits'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel" v-html="textCancel" v-if="type != 'alert'"></button>
                    <button @click="save" class="act save" :class="classSave" v-html="selectedEl != undefined && (typeof list[selectedPos].config.level === 'undefined' || list[selectedPos].config.level > level) ? textSaveSupervisor : textSave"></button>
                </div>
                <div v-else-if="type == 'disconnect'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel" v-html="textCancel" v-if="type != 'alert'"></button>
                    <button @click="save" class="act save" :class="classSave" v-html="textSave" v-if="type != 'media' && type != 'library' && !(type == 'select' && cType == 4)"></button>
                </div>
                <div v-else-if="type == 'temperature'" class="buttons">
                    <button @click="cancel('button')" class="act notNow" v-html="textNotNow" v-if="[3, 4].includes(tempSensorStep)"></button>
                    <button @click="cancel('button')" class="act cancel" v-html="textCancel" v-if="tempSensorStep == 1"></button>
                    <button @click="save" class="act save" v-html="textSave" v-if="[2, 4].includes(tempSensorStep)"></button>
                </div>
                <div v-else-if="type === 'media'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel">{{ getString('menu_aux.action.cancel') }}</button>
                    <!-- <button @click="imgEdit" class="act save">{{ getString('menu_aux.action.edit') }}</button> -->
                    <button v-if="!$data.logbook" @click="imgDelete" class="act save">{{ getString('menu_aux.action.delete') }}</button>
                </div>
                <div v-else-if="type === 'previewSignature'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel">{{ getString('popup.media.text_cancel') }}</button>
                </div>
                <div v-else-if="type === 'itemSettings'" class="buttons">
                    <button @click="cancel('button')" class="act cancel" :class="classCancel">{{ getString('menu_aux.action.cancel') }}</button>
                    <button @click="save" class="act save" :class="classSave">{{ getString('menu_aux.action.save') }}</button>
                </div>
                <div v-else class="buttons">
                    <button @click="cancel2('button')" class="act cancel" :class="[classCancel]" v-html="textCancel2" v-if="textCancel2"></button>
                    <button @click="cancel('button')" :class="[{ 'act save': classSave }, { 'act cancel': !classSave }, { full: type == 'confirmUpload' }]" v-html="textCancel" v-if="type != 'alert'"></button>
                    <button @click="saveStart" class="act save start" :class="classSaveStart" v-html="textSaveStart" v-if="type == 'select' || type == 'selectDouble' || type == 'issueAlert' || type == 'confirmUpload'"></button>
                    <button @click="save" class="act save" :class="classSave" v-html="textSave" v-if="type != 'media' && type != 'library' && !(type == 'select' && cType == 4)"></button>
                    <button @click="logout" class="act logout" :class="classLogout" v-html="textLogout" v-if="type == 'login'"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Numpad from '@/components/inputs/Numpad'
import PopupTemperature from '@/plugins/popup/PopupTemperature'
import PopupTicket from '@/plugins/popup/PopupTicket'
import PopupDateTime from '@/plugins/popup/PopupDateTime'
import PopupRelease from '@/plugins/popup/PopupRelease'
import PopupGeolocation from '@/plugins/popup/PopupGeolocation'
import PopupResource from '@/plugins/popup/PopupResource'
import SearchBar from '../../components/domain/ui/SearchBar.vue'
import LottieAnimation from '@/components/layout/LottieAnimation.vue'

import i18n from '@/i18n'
import draggable from 'vuedraggable'
import store from '@/store'
import router from '@/router'
import LibraryDetail from '@/components/domain/library/detail'

import vueSignature from 'vue-signature'
Vue.use(vueSignature)
export default {
    components: {
        LottieAnimation,
        PopupTemperature,
        PopupTicket,
        LibraryDetail,
        Numpad,
        PopupDateTime,
        PopupRelease,
        PopupGeolocation,
        PopupResource,
        SearchBar,
        draggable
    },
    data() {
        return {
            mood: 'normal',
            decimal: false,
            show: false,
            hasNegative: false,
            closable: true,
            blocking: true,
            type: '',
            title: '',
            message: '',
            messageError: '',
            showingError: false,
            list: [],
            level: 3,
            listSec: {},
            listEmpty: '',
            textLogout: '',
            textCancel: '',
            textCancel2: '',
            textNotNow: '',
            textSave: '',
            textSaveSupervisor: '',
            textSaveStart: '',
            textNext: '',
            textPrev: '',
            classLogout: '',
            classCancel: '',
            classSave: '',
            classSaveStart: '',
            classNext: '',
            classPrev: '',
            inputVal: '',
            productName: '',
            image: false,
            file: false,
            selected: false,
            selectedSec: false,
            selectedEl: undefined,
            selectedPos: undefined,
            selectedSecEl: undefined,
            selectedTab: '',
            selectedSecTab: '',
            disabledMessage: '',
            disNumpad: false,
            dateRange: {},
            dateRangeTwo: {},
            dateStart: '',
            dateEnd: '',
            processData: {},
            processTypes: [],
            currentStep: 0,
            data: [],
            cType: false,
            customClass: '',
            date: '',
            time: '',
            resource: false,
            oldDate: '',
            oldTime: '',
            signOptions: {
                penColor: '',
                backgroundColor: '',
                format: '',
                watermark: ''
            },
            tempOptions: {
                maxRange: null,
                minRange: null,
                maxNum: 260,
                minNum: -40,
                stepNum: 1,
                numDec: 1,
                stepDec: 1,
                mesure: 'ºC'
            },
            itemSettings: {},
            tempSensorStep: 1,
            routePath: false,
            showSaveStart: true,
            updatedDayCalendarOne: 0,
            updatedDayCalendarTwo: 0,
            callLogout: function () {},
            callCancel: function () {},
            callCancel2: function () {},
            callSave: function () {},
            callSaveStart: function () {},
            callEdit: function () {},
            callDelete: function () {},
            callRemoveAll: function () {},
            callAfterAccess: function () {},
            columnHeaders: null,
            searchBar: false,
            search: '',
            timerSearch: false,
            newHeaders: [],
            tablePopup: '',
            disabledAlexIcon: ['tableColumn', 'media', 'select', 'library', 'resource', 'emailAlert', 'renew', 'itemSettings'],
            only_past: false,
            tplName: null
        }
    },
    computed: {
        aLocale() {
            var language = store.getters['getLanguage']
            parseLang(language)
            return language
            // return store.getters['getLocale']
        },
        postponeTomorrow() {
            return moment(this.dateStart).format('YYYY-MM-DD')
        },
        dateRangeFirstCalendar() {
            return this.dateRange
        },
        filteredList() {
            if (this.search === '') {
                return this.list
            } else {
                return this.list.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()))
            }
        },
        orderedEmployeesList() {
            if (!this.list) {
                return null
            }

            const employeesList = Object.values(this.list)

            if (employeesList.length === 0) {
                return null
            }

            const orderedList = employeesList.sort(this.compareNames)

            return orderedList
        }
    },
    methods: {
        checkHeader(header) {
            const visibleHeadersCount = this.$data.headers.reduce((count, h) => count + (h.visible ? 1 : 0), 0)
            if (visibleHeadersCount <= 1 && header.visible) {
                return
            } else {
                header.visible = !header.visible
            }
            this.$forceUpdate()
        },

        tempUpdateSensorStep(newVal) {
            log('tempUpdateSensorStep', newVal)
            this.tempSensorStep = newVal
        },
        redirectPath(firstResult) {
            var visible = []
            var tools = store.getters['getTools']
            var result = firstResult
            const config = store.getters['login/getConfig']

            if (config.tools) {
                visible = config.tools
            }
            if (visible.length == 1) {
                result = tools[visible[0]].href
            }

            return result
        },

        momentFormat(date) {
            return moment(date).format('dddd D')
        },
        dayClickedCalendarOne(e) {
            this.updatedDayCalendarOne++
        },
        dayClickedCalendarTwo(e) {
            this.updatedDayCalendarTwo++
        },

        selectMe(id, key) {
            this.selected = id
            this.changeValue('')
            if (this.selectedEl == id) {
                this.disNumpad = true
                this.selectedEl = undefined
            } else {
                this.disNumpad = false
                this.selectedEl = id
            }
            if (typeof key !== 'undefined') this.selectedPos = key
        },
        selectMeSec(id) {
            this.selectedSec = id
            this.changeValue('')
            if (this.selectedSecEl == id) {
                this.disNumpad = true
                this.selectedSecEl = undefined
            } else {
                this.disNumpad = false
                this.selectedSecEl = id
            }
        },
        selectTab(id) {
            this.selectedTab = id
            this.selected = undefined
            this.selectedEl = undefined
            this.disNumpad = true
            this.changeValue('')
            this.showingError = false
        },

        changeValue(newVal) {
            this.inputVal = newVal
        },

        getString(str) {
            return i18n.t(str)
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },
        getStringPlural(str, pos) {
            return i18n.tc(str, pos)
        },
        getStringVariable(str, pos) {
            return i18n.t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return i18n.tc(str, pos, { s: text })
        },

        // Para limitar fechas (ver vuetify)
        // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,

        cancel(item) {
            // check if is closable or if is legal_conditions, click in cancel button
            if (this.closable || (item == 'button' && this.customClass == 'popup-small')) {
                item = item || false
                this.callCancel(this, store, item)
            } else if (item == 'cross') {
                this.callCancel(this, store, item)
            }
        },

        cancel2(item) {
            this.callCancel2(this, store, item)
        },

        save() {
            if (this.type == 'sign' || this.type == 'freeSign') {
                this.signSave()
            } else if (this.type == 'process') {
                var finish = []
                for (var k in this.data) {
                    var d = this.data[k]
                    if (d.optional || (d.result && d.result != null && d.result != '')) {
                        finish.push('true')
                    } else {
                        finish.push('false')
                    }
                }
                if (finish.indexOf('false') == -1) {
                    this.callSave(this, store)
                }
            } else if (this.type == 'ticket') {
                let formData = this.$refs['popupTicket'].validateForm()
                let self = this

                if (formData) {
                    // show loader
                    self.$overlay.loading({
                        blocking: true
                    })

                    // prepare data
                    let params = {
                        location_id: formData.location_id,
                        employee_id: '',
                        tool: formData.tool,
                        description: formData.description,
                        email: formData.email,
                        data: {
                            location: formData.location,
                            name: formData.employee_id
                        }
                    }

                    // store add ticket
                    store.dispatch('ticket/addTicket', params).then(function (response) {
                        // close loader
                        self.$overlay.hide()

                        // return event result
                        self.callSave(this, store)
                    })
                }
            } else if (this.type == 'dateTime') {
                const data = this.$refs['dateTime'].getData()
                this.date = data.date
                this.time = data.time
                this.oldDate = data.oldDate
                this.oldTime = data.oldTime
                this.callSave(this, store)
            } else if (this.type == 'tableColumn') {
                // save Supervise table columns order
                this.$data.headers = this.columnHeaders

                this.$forceUpdate()
                this.callSave(this, store)
            } else {
                this.callSave(this, store)
            }
        },
        saveStart() {
            this.callSaveStart(this, store)
        },
        logout() {
            this.callLogout(this, store)
        },
        logoutStore() {
            router.push('/login')
            this.callCancel(this, store)
        },

        // Funciones propias de SIGN
        signSave() {
            if (!this.$refs.ref_sign.isEmpty()) {
                if (typeof this.list[this.selectedEl] !== 'undefined') {
                    this.signAddWaterMark()
                }

                if (this.signOptions.format == 'jpg') {
                    this.inputVal = this.$refs.ref_sign.save('image/jpeg')
                } else if (this.signOptions.format == 'svg') {
                    this.inputVal = this.$refs.ref_sign.save('image/svg+xml')
                } else if (this.signOptions.format == 'png') {
                    this.inputVal = this.$refs.ref_sign.save()
                } else {
                    // Guarda en PNG
                    this.inputVal = this.$refs.ref_sign.save()
                }
            } else {
                this.inputVal = ''
            }
            this.callSave(this, store)
        },
        signClear() {
            this.$refs.ref_sign.clear()
        },
        signUndo() {
            this.$refs.ref_sign.undo()
        },
        signAddWaterMark() {
            this.$refs.ref_sign.addWaterMark({
                text: i18n.t('popup.sign.watermark') + this.list[this.selectedEl].name,
                font: '12px Arial',
                x: 10,
                y: 180
                // -- All properties
                // text:"mark text",          // watermark text, > default ''
                // font:"20px Arial",         // mark font, > default '20px sans-serif'
                // style:'all',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
                // fillStyle:"#333333",           // fillcolor, > default '#333'
                // strokeStyle:"blue",	       // strokecolor, > default '#333'
                // x:100,                     // fill positionX, > default 20
                // y:200,                     // fill positionY, > default 20
                // sx:100,                    // stroke positionX, > default 40
                // sy:200,                     // stroke positionY, > default 40
            })
        },
        signFromDataURL(url) {
            this.$refs.ref_sign.fromDataURL('data:image/png;base64,iVBORw0K...')
        },

        // Funciones propias de process
        generateProcess() {
            var self = this

            var label = self.processData ? self.processData.label : false
            var selectedState = self.processData && Object.keys(self.processData.selectedState).length > 0 ? self.processData.selectedState : { id: label.states[Object.keys(label.states)[0]].id, name: label.states[Object.keys(label.states)[0]].name }
            var format = store.getters['printer/getFormat'](label.states[selectedState.id].format)

            self.data = []
            self.processTypes = []
            self.currentStep = 0

            self.processTypes.push('state')
            self.data.push({
                tags: label.states,
                title: i18n.t('label.popup.process.state'),
                message: i18n.t('label.popup.process.state_msg'),
                result: selectedState ? selectedState.name : '',
                selected: selectedState ? selectedState.id : '',
                keyname: 'state'
            })

            if (format && format.fields) {
                for (var i in format.fields) {
                    var step = format.fields[i]
                    self.processTypes.push(step.type)
                    if (step.type == 'date') {
                        var maxDate = undefined

                        // if(self.data[0].selected != '' && self.processTypes[0] == 'state'){
                        //   maxDate = moment().add(label.states[self.data[0].selected].time, 'minutes').format('D/M/YYYY');
                        // }
                        self.data.push({
                            title: step.name,
                            result: '',
                            input: {},
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().format('DD/MM/YYYY'),
                            maxDate: maxDate
                        })
                    } else if (step.type == 'datetime') {
                        var maxDate = undefined

                        self.data.push({
                            title: step.name,
                            result: '',
                            date: {},
                            time: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().format('DD/MM/YYYY'),
                            maxDate: maxDate
                        })
                    } else {
                        self.data.push({
                            title: step.name,
                            result: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional
                        })
                    }
                }
            }
            self.processTypes.push('quantity')
            self.data.push({
                title: i18n.t('label.popup.process.quantity'),
                result: '',
                max: 100,
                message: i18n.t('label.popup.process.quantity_msg'),
                keyname: 'quantity'
            })

            if (Object.keys(label.states).length <= 1) {
                self.currentStep++
            }
            // for (var o in self.data) {
            //   var dt = self.data[o];
            //
            //   if (dt.result != "" && o == 0) {
            //     self.currentStep++;
            //   } else if (dt.result != "" && self.data[o - 1].result != "") {
            //     self.currentStep++;
            //   }
            // }

            self.processData.selectedState = selectedState
        },

        setProcessResult(pType, i, result) {
            this.processBeforeChanges(pType, i, result)

            if (pType == 'state') {
                this.processData.selectedState.name = result.name
                this.processData.selectedState.id = result.id
                // this.data[i].result = result.name;
                // this.data[i].selected = result.id;

                this.generateProcess()
                // Calculo de maxDate en funcion de estado
                // var datePos = this.processTypes.indexOf('date');
                // this.data[datePos].maxDate = moment().add(this.data[i].tags[result.id].time, 'minutes').format('D/M/YYYY');
            } else if (pType == 'date') {
                this.data[i].result = result.selectedDate
            } else if (pType == 'datetime') {
                let date = Object.keys(this.data[i].date).length > 0 ? this.data[i].date.selectedDate : ''
                let time = this.data[i].time ? this.data[i].time : ''

                this.data[i].result = date + ' ' + time
            } else {
                this.data[i].result = result
            }

            this.processAfterChanges(pType, i, result)
        },
        setProcessValue(pType, i, result) {
            this.processBeforeChanges(pType, i, result)

            this.data[i].result = result

            this.processAfterChanges(pType, i, result)
        },

        processBeforeChanges(pType, i, result) {
            var hasResult = this.data[i].optional || (this.data[i].result && this.data[i].result != null && this.data[i].result != '')

            if (hasResult && this.data[i].result != result) {
                for (var k = i; k < this.data.length; k++) {
                    var datos = this.data[k]
                    datos.result = ''
                    if (this.processTypes[k] == 'date') {
                        // datos.input = {};
                    } else if (this.processTypes[k] == 'datetime') {
                        // datos.input = {};
                    }
                }
            }
        },
        processAfterChanges(pType, i, result) {
            if (pType != 'date' && pType != 'datetime' && pType != 'number' && pType != 'state') {
                if (this.currentStep + 1 < this.data.length) {
                    this.currentStep++
                }
            }
        },

        changeStep(goTo) {
            let item = this.data[this.currentStep]
            let result = item.result
            let validResult = item.optional || (result && result != null && result != '')
            let isNext = this.currentStep + 1 == goTo

            if (item.type == 'datetime') {
                validResult = item.optional || (result && result != null && result != '' && item.date && Object.keys(item.date).length > 0 && item.time && item.time != null && item.time != '')
            }

            if (this.currentStep != goTo && goTo >= 0 && ((this.currentStep < goTo && validResult && isNext) || this.currentStep > goTo)) {
                this.currentStep = goTo
            }
        },

        // Funciones propias date range
        setRangeResult(result) {
            // this.inputVal = result.selectedDate;
        },

        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
            }, 800)
        },
        cleanSearch() {
            this.search = ''
        },
        imgDelete() {
            this.callDelete(this, store)
        },
        imgEdit() {
            console.warn('imgEdit', this)
            this.callEdit(this, store)
        },

        // to sort employeesList
        compareNames(a, b) {
            const nameA = a.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')

            const nameB = b.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }

            return 0
        }
    },
    watch: {},
    created() {
        // KEYBOARD ACTIONS HANDLER
        const escapeHandler = (e) => {
            if (e.key === 'Escape' && this.show) {
                this.cancel()
            }
        }
        const enterHandler = (e) => {
            if (e.key === 'Enter' && this.show) {
                this.save()
            }
        }

        document.addEventListener('keydown', escapeHandler)
        document.addEventListener('keydown', enterHandler)

        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', escapeHandler)
            document.removeEventListener('keydown', enterHandler)
        })
    },
    mounted() {
        this.columnHeaders = this.$data.headers
        if (this.type == 'process') {
            this.generateProcess()
        }

        if (this.type == 'postpone') {
            if (Object.values(this.list).length == 1) {
                this.selected = '0'
                this.data.showReason = true
            }
            // AUTO SELECT DATE FIRST TIME
            this.$nextTick(function () {
                var aDays = document.querySelectorAll('.vfc-day :not( .vfc-disabled ) ')
                var selectedDay = this.data && this.data.selectedDate && this.data.selectedDate.selectedDate ? this.data.selectedDate.selectedDate.split('-')[2] : false

                if (selectedDay) {
                    for (var day in aDays) {
                        if (aDays[day].innerText == selectedDay) {
                            aDays[day].click()
                            break
                        }
                    }
                }
            })
        }

        if (this.type == 'select' && this.list && Object.keys(this.list).length > 0) {
            this.selectMe(this.list[Object.keys(this.list)[0]].id)
        }
    },
    directives: {
        focus: {
            bind: function (el, binding) {
                el.focus()
            },
            componentUpdated: function (el, binding) {
                if (binding.value) {
                    el.focus()
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// .v-picker--date {
//     .v-picker__title {
//         background-color: $main-t90;

//         .v-date-picker-title__year {
//             @include font-size(20);
//         }
//         .v-date-picker-title__date {
//             div {
//                 @include font-size(26);
//                 color: $main-s60;
//                 font-family: $text;
//             }
//         }
//     }

//     .v-picker__body {
//         .v-date-picker-header .accent--text {
//             text-align: center;

//             button {
//                 color: $main-dark !important;
//                 background-color: #fff;

//                 &:hover {
//                     background-color: $main-dark !important;
//                     color: #fff !important;
//                 }
//             }
//         }
//     }

//     .v-date-picker-years {
//         li {
//             text-align: center;
//         }
//     }

//     .v-btn .v-btn__content {
//         font-family: $text-light;
//     }
//     .v-btn--active {
//         background-color: $main !important;
//         .v-btn__content {
//             font-family: $text;
//         }
//     }
//     .v-btn--outline {
//         border-color: #aaa !important;
//     }
//     button {
//         &:hover {
//             background-color: $main !important;
//             color: #fff !important;
//         }
//     }
// }

.popup-container {
    @include display-flex();
    @include align-items();
    @include justify-content();
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z-popups-unblocking;
    max-width: calc(100%);
    max-height: calc(100%);
    width: calc(100%);
    height: calc(100%);
    // top: $menu-top-height;
    // z-index: $z-popups-default;
    // max-width: calc(100% - #{$menu-tools-min});
    // max-height: calc(100% - #{$menu-top-height});
    // width: calc(100% - #{$menu-tools-min});
    // height: calc(100% - #{$menu-top-height});

    &.blocking {
        z-index: $z-overlay-blocking + 2;
    }
    // &:not(.blocking) {
    //     // top: $menu-top-height;
    //     // max-height: calc(100% - #{$menu-top-height});
    // }

    &.popup-small {
        .popup {
            max-width: 550px;

            .pop-body {
                min-width: 450px;
                padding: 10px;

                .title {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .paragraph {
                    margin: 10px 0;
                }
            }
        }
    }
    &.popup-label {
        position: absolute;
        width: calc(100% - #{$menu-tools-min});
        max-width: calc(100%);
        height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
        top: $menu-top-height + $alex-app-padding;
        left: $menu-tools-min - $alex-app-padding;
        border-radius: $default-border-radius;
        .pop-footer {
            display: none;
        }
    }
    &.auditPoints {
        .pop-body {
            .kind-of {
                display: flex !important;
                flex-direction: column !important;
                height: 200px !important;
                justify-content: flex-start !important;

                .audit-score {
                    width: 100%;
                    @include font-size(60px);
                    font-family: $text-bold;
                    color: $neutro-s80;
                    height: fit-content;
                    text-align: center;
                    height: 155px !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                }
            }
        }
        .pop-footer {
            min-height: 75px !important;
        }
    }

    &.item-settings {
        .popup {
            .pop-body {
                padding-top: 8px;
            }
        }
    }

    &.popup-label-admin {
        position: absolute;
        width: calc(100% - #{$menu-tools-min});
        max-width: calc(100%);
        height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
        top: $menu-top-height + $alex-app-padding;
        left: $menu-tools-min - $alex-app-padding;
        border-radius: $default-border-radius;
    }

    &.tablePopup {
        height: 100%;
        max-height: 100%;
        .popup {
            position: absolute;
            max-height: calc(100% - 16px);
            right: 8px;
            bottom: 8px;
            top: 8px;
            width: 400px;
            height: 100%;
            @include display-flex();
            @include justify-content(start);

            h1 {
                padding-left: 0px;
                @include font-size(24px);
            }
            .pop-body {
                flex-direction: column;
                height: 100%;
                width: 100%;
                @include justify-content(start);

                .kind-of.check-container {
                    overflow-x: hidden;
                    margin-top: 0px;
                    width: 100%;
                    @include justify-content(start);
                    .check-box-container {
                        padding-left: 30px;

                        .check-box {
                            width: 328px;
                            &:hover {
                                background-color: #e2e2e2;
                            }
                            &:active {
                                cursor: grab;
                                &:active {
                                    cursor: grabbing;
                                }
                            }
                        }
                    }
                    .check-box-container:hover {
                        background-image: img('drag_dots.svg');
                        background-repeat: no-repeat;
                        background-position: 8px;
                    }
                    .check-title {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .pop-footer {
                height: 17%;
                div.buttons {
                    flex-direction: column;
                    gap: 8px;
                    justify-content: flex-end;
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    &.renew {
        .popup {
            border-top: 8px solid $color-warning-500;
            &.renew {
                .kind-of {
                    flex-direction: column;
                    @include align-items();
                    .title {
                        @include font-size(ml);
                        font-family: $text-bold;
                        margin-bottom: 16px;
                    }

                    .message {
                        background: transparent;
                        text-align: center;
                    }

                    &.portrait {
                        .message {
                            @include font-size(s);
                            margin-bottom: 16px;
                        }

                        .title {
                            @include font-size(sm);
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }

        .pop-footer {
            .buttons {
                display: block !important;
                width: fit-content !important;

                .act {
                    background-color: $color-secondary-500;
                }
            }
        }
    }

    &.offline {
        .popup {
            .pop-footer {
                .buttons {
                    .act.save {
                        display: none;
                    }
                }
            }
        }
    }

    .popup {
        @include display-flex();
        @include align-items();
        @include justify-content();
        @include flex-direction($dir: column);
        @include border-radius(4px);
        position: relative;
        background-color: #fff;
        max-width: 700px;
        max-height: 90%;
        width: calc(100% - 20px);
        height: auto;
        // padding: 2em;
        padding: 8px;
        gap: 1.5em;

        .pop-alex {
            @include background($size: rem(60px), $image: img('alexhead_06.svg'), $color: #fff);
            @include border-radius(100%);
            border: solid 3px $main-t80;
            width: rem(80px);
            height: rem(80px);
            display: block;
            position: absolute;
            top: rem(-55px);
            left: rem(15px);
            z-index: $z-popups-default + 1;

            &.normal {
                background-image: img('alex_carita_07.svg');
            }
            &.geolocation {
                background-image: img('alex_carita_10.svg');
                width: 80px;
                height: 80px;
            }
        }

        .pop-alex-lottie {
            @include border-radius(100%);
            background-color: white;
            border: solid 3px $main-t80;
            width: rem(64px);
            height: rem(64px);
            display: block;
            position: absolute;
            top: rem(-45px);
            left: rem(15px);
            z-index: $z-popups-default + 1;
            div#app {
                height: 100%;
                display: grid;
                place-content: center;
                div {
                    height: 50%;
                    margin-top: 35%;
                    display: grid;
                    place-content: center;
                    svg {
                        width: 54px !important;
                        height: 54px !important;
                    }
                }
            }
        }

        .pop-close {
            @include background($size: 20px, $image: img('close_primary.svg'), $color: $main-t30);
            width: rem(40px);
            height: rem(40px);
            display: inline-block;
            float: right;
        }

        .pop-header,
        .pop-body,
        .pop-footer {
            @include display-flex();
            @include justify-content();
            width: 100%;
            height: auto;
            overflow: auto;
            .buttons {
                .act {
                    flex: 1;
                    box-sizing: border-box;
                }
            }
        }

        .pop-header {
            @include align-items(flex-start);
            min-height: rem(40px);
            padding-left: rem(70px);
            padding-right: rem(30px);
            margin-top: -10px;

            .title-message {
                font-family: $text;
                color: $main;
                padding: 0;
                background-color: $main-t90;
                border-color: $main-t90;
                padding: 8px 16px;
                border-radius: 3px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-right-color: inherit;
                    border-left: 0;
                    // border-bottom: 0;
                    margin-top: -6px;
                    margin-left: -6px;
                }
            }
        }

        .pop-body {
            // @include flex-wrap(wrap);
            @include width-min-content();
            max-height: calc(100% - #{rem(80px)});
            height: auto;
            min-width: unset;
            // min-width: 100%;
            width: 100%;
            max-width: unset;
            overflow: auto;
            padding: 0px;
            padding-top: 23px;
            .search-container {
                input {
                    border: 1px solid $color-neutral-300;
                    background-color: #fff;
                }
                input[type='search']::placeholder {
                    @include font-size(m);
                    width: 100%;
                    min-width: 200px;
                }
            }
            .kind-of {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(center);
                @include align-items(flex-start);
                // max-height: calc(100% - 125px);
                height: max-content;
                min-width: 150px;
                // min-width: 100%;
                width: 100%;
                // overflow: auto;
                p.issue-name {
                    font-size: 15px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                }

                &.item-settings {
                    @include display-flex();
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(center);

                    .title {
                        @include font-size(l);
                        font-family: $text-bold;
                        text-transform: uppercase;
                        margin-bottom: 16px;
                    }
                }
            }

            .kind-of.check-container {
                overflow-x: hidden;
                margin-top: -1.8em;
                color: red;
                flex-direction: column;
                h1 {
                    font-size: 24px;
                    text-align: left;
                }

                div.check-box {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 51px;
                    padding: 0px 13.6px;
                    align-items: center;
                    margin-bottom: 8px;
                    flex-shrink: 0;
                    border-radius: 6.8px;
                    border: 1.7px solid var(--color-neutral-500, #e2e2e2);
                    cursor: pointer;
                    div.check-input {
                        background-color: $color-neutral-500;
                        @include background($image: img('tick_unselected.svg'), $position: center, $size: 16px);
                        border-radius: 3px;
                        display: flex;
                        width: 25px;
                        height: 25px;
                        padding: 10px 12px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        transition: background-color 0.5s;
                    }
                    .check-title {
                        margin-left: 8px;
                    }
                }

                div.check-box.activeHeader {
                    div.check-input {
                        background-color: $main-dark;
                        @include background($image: img('tick_ffffff.svg'), $position: center, $size: 16px);
                    }
                }
            }

            .left,
            .right {
                @include display-inline-flex();
                @include flex-direction($dir: column);
                @include justify-content($val: flex-start);
                @include align-items();
                width: 50%;
                // height: auto;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
            }
            .full {
                @include display-inline-flex();
                @include flex-direction($dir: column);
                @include justify-content($val: flex-start);
                @include align-items();
                width: 100%;
                // height: auto;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
            }

            .disabled {
                cursor: not-allowed;
                position: relative;

                > div,
                > ul {
                    &:before,
                    &:after {
                        width: calc(100%);
                        height: calc(100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &:before {
                        @include font-size(xl);
                        line-height: 125%;
                        font-family: $conden-bold;
                        color: $main-s30;
                        content: attr(data-msg);
                        text-align: center;
                        z-index: 2;
                        padding: 45% 24px;
                    }
                    &:after {
                        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%), linear-gradient(to top, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%), linear-gradient(to left, rgba(255, 255, 255, 1), rgba($main-t90, 0.8), rgba(255, 255, 255, 1));
                        content: '';
                    }
                }
            }

            .message {
                @include font-size(m);
                padding: 10px 14px;
                font-family: $text-light;
                background-color: $warning-t60;
                position: relative;
                min-width: 100%;
                line-height: 25px;
                width: 100%;

                &.emailAlert {
                    text-align: center;

                    .image-preview {
                        width: 100px;
                    }
                }

                // &:after {
                //     content: '';
                //     display: block; /* reduce the damage in FF3.0 */
                //     position: absolute;
                //     top: -12px;
                //     left: 15px;
                //     width: 0;
                //     border-width: 15px 15px 0;
                //     border-style: solid;
                //     border-color: $default-bg-color transparent;
                //     transform: rotate(180deg);
                // }
            }

            .message-info {
                @include font-size(s);
                margin: 6px 0 8px 0;
                color: $main-s20;
                border-bottom: 1px solid $main-s10;
                text-align: left;
                width: 100%;
            }

            .message-error {
                @extend .message-info;
                color: $error;
                border-color: $error;
            }

            .place {
                width: 100%;
                border-bottom: 1px solid $main-t70;

                .item {
                    @include border-top-radius(3px);
                    font-family: $text;
                    display: inline;
                    float: left;
                    width: 33%;
                    padding: 10px 10px;
                    text-align: center;
                    color: $main-s50;
                }

                .item.selected {
                    background-color: $main-t90;
                }
            }

            .list {
                min-width: 150px;
                width: 100%;
                height: 100%;

                .item {
                    @include border-radius(3px);
                    @include display-flex();
                    @include align-items();
                    height: rem(40px);
                    width: 100%;
                    border-bottom: 1px solid #eee;

                    &.default {
                        // background-color: #fbfbfb;
                        border-bottom-color: $main-t30;
                    }
                    &.selected {
                        background-color: $main-t90;
                        border-bottom: 2px solid $main;
                    }

                    .user-color {
                        @include dftUserColor();
                        @include text-ellipsis();
                        width: 35px;
                        height: 35px;
                        display: inline;
                        margin: 0 5px;

                        &:after {
                            padding: 12px 0;
                        }
                    }

                    .user-name {
                        @include text-ellipsis();
                        font-family: $text;
                    }
                }

                &.select-list {
                    .item {
                        @include display-flex();
                        @include align-content(center);
                        @include interaction();
                        padding-left: 12px;
                        overflow: hidden;
                        line-height: 1.1;

                        .name {
                            @include text-ellipsis($line: 2);
                        }
                    }
                }
            }

            .toolbar {
                width: 100%;
                height: rem(40px);
                display: flex;
                justify-content: space-around;

                .btn {
                    @include font-size(s);
                    text-transform: none;
                    color: #777;
                    background-color: transparent;
                    margin: 0 6px;
                    padding: 0 6px;
                }
            }
        }

        .pop-footer {
            @include border-radius(0 0 3px 3px);
            // min-height: 125px;
            background-color: #fff;

            .buttons {
                @include display-flex();
                @include align-items(flex-end);
                @include flex-direction(row);
                gap: 1em;
                width: 100%;
                height: auto;
                text-align: right;

                button {
                    @include border-radius(4px);
                    @include font-size(m);
                    font-family: $text-bold;
                    border: 1px solid rgba(131, 131, 131, 0.03);
                    height: auto;
                    padding: 0.8em 1em;
                    // height: 54px;
                    color: $main;
                    background-color: $main-t90;
                    width: 49%;
                    overflow: hidden;
                    cursor: pointer;

                    &.full {
                        width: 100%;
                    }

                    // &:first-child {
                    //     // margin-right: 0;
                    // }

                    &:only-child {
                        width: 100%;
                    }

                    &.save {
                        @include font-size(m);
                        color: #fff;
                        background-color: $main;
                        font-family: $text-bold;
                        border: none;
                    }
                    &.logout {
                        // @include default-box-shadow();
                        // font-weight: bold;
                        float: left;
                    }
                }
            }
        }

        &.quantity {
            .left {
                @include justify-content();
            }

            .message {
                margin-top: 20px;
            }

            .pin {
                @include font-size(xl);
            }

            .rem-amount {
                @include font-size(s);
                color: $error;
                margin-top: 40px;
                font-family: $text;
            }
        }

        &.offlineAlert {
            border-top: 8px solid $color-error-500;
            .pop-body {
                .kind-of {
                    .message {
                        background-color: transparent;
                        text-align: center;
                        font-weight: bold;
                    }
                }
            }
            .pop-footer {
                .buttons {
                    .act.save {
                        display: block;
                    }
                }
            }
        }

        &.media,
        &.previewSignature {
            @include justify-content($val: flex-end);
            max-width: 95%;
            max-height: 90%;

            .pop-body {
                height: auto;
                // height: 100%;
                min-width: unset;
                width: 30%;
                overflow-y: auto;
                text-align: center;
            }
            .kind-of {
                width: unset;
                min-width: unset;
                height: unset;
                &.preview {
                    margin: 10px;
                    overflow: auto;
                    text-align: center;
                    width: 100%;

                    div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        &.previewSignature {
            max-width: 90%;
        }

        &.select {
            gap: 12px;
            .pop-body {
                overflow: hidden;
                .full {
                    padding: 10px 0px 0px 0px;
                }
                .kind-of {
                    display: inline;
                }
            }

            .list {
                overflow: auto;
                height: 300px;
                padding-right: 6px;
                .item {
                    border: 1px solid $color-neutral-300;
                    margin-bottom: 12px;
                    overflow: hidden;
                    margin-right: 0px;
                }
                .item.default {
                    border-bottom-color: $color-neutral-300;
                }
                .item.selected {
                    border: 1.7px solid $color-neutral-300;
                }
                .item:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        &.select-double {
            .pop-body {
                padding: 10px;
            }

            .list {
                .item {
                    padding: 0 8px;
                }
            }
        }

        &.numeric {
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                // position: relative;
                overflow: auto;

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding-bottom: 10px;
                }
            }

            // .rem-amount {
            //     @include font-size(s);
            //     color: $error;
            //     font-family: $text;
            //     margin: 12px 0;
            //     width: 100%;
            // }
        }

        &.temperature {
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                overflow: auto;

                .kind-of {
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        &.list_audits {
            gap: 12px;
            .pop-body {
                overflow: hidden;
                .full {
                    padding: 10px 0px 0px 0px;
                }
                .list {
                    overflow: auto;
                    height: 305px;
                    padding-right: 6px;
                    .item {
                        line-height: 1.1;
                        border: 1px solid $color-neutral-300;
                        margin-bottom: 12px;
                        overflow: hidden;
                        margin-right: 0px;

                        .audit {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            text-overflow: clip;
                            overflow: hidden;
                            height: auto;
                        }
                    }
                    .item.selected {
                        border: 1.7px solid $color-neutral-300;
                    }
                    .item:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        &.ticket {
            width: 90%;
            max-width: 1200px;

            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                overflow: auto;
                width: 100%;
                height: auto;

                .kind-of {
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        &.library {
            .pop-body {
                width: auto;

                .kind-of {
                    @include flex-direction(column);
                    @include align-items();
                    overflow: auto;

                    .container-preview {
                        height: 70%;
                    }
                }
            }
        }

        &.date,
        &.dateRange {
            width: 100%;
            max-width: 750px;
            max-height: 500px;
            .kind-of {
                padding: 0px;
            }
            .pop-body {
                width: 100%;
                height: 300px;

                .kind-of {
                    width: 100%;
                    height: 100%;
                    @include display-flex();
                    gap: 20px;
                }
            }
            .pop-footer {
                max-height: 77px;
                min-height: 77px;
            }
        }

        &.input {
            .pop-body {
                margin: 12px 0;
                padding: 0 12px;

                .kind-of {
                    @include flex-direction(column);

                    input {
                        width: 100%;
                    }

                    .message-error {
                        @include width-fit-content();
                    }
                }
            }
        }

        &.sign {
            gap: 0.5em;

            .pop-body {
                // min-width: 700px;
                // width: auto;

                .disabled > div:before {
                    max-width: 100%;
                    max-height: 100%;
                    padding: 0 !important;
                    display: grid;
                    place-items: center;
                    font-size: 1.3em !important;
                }

                .right {
                    //   width: calc(60% + 50px);
                    padding: 0;

                    .toolbar {
                        @include display-flex();
                        @include justify-content(space-around);

                        button {
                            max-width: 50% !important;
                            width: fit-content;
                        }
                    }
                }
                .left {
                    //   width: calc(40% - 50px);
                    max-height: 50vh;

                    .users-list {
                        height: 100%;
                        overflow: auto;
                    }
                }

                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                    background-color: white;
                }
            }
        }

        &.freeSign {
            .pop-body {
                min-width: 300px;

                .kind-of {
                    @include flex-direction(column);
                    width: auto;
                    overflow: hidden;
                }
                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                    overflow: hidden;
                }
            }
        }

        &.issueAlert {
            height: fit-content;
            // gap: 0em;

            .pop-body {
                @include flex-wrap(wrap);

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();

                    .message {
                        width: 100%;
                        background-color: $warning-t60;
                        max-height: 200px;
                        overflow: auto;
                    }

                    .suggest {
                        width: 100%;
                        // height: 40px;
                        height: 48px;
                        height: 100%;
                        margin-top: 32px;
                        text-align: left;
                        color: $color-neutral-600;
                        p.issue-name {
                            font-weight: bold;
                        }
                    }

                    .issue-title,
                    .issues,
                    .alert-title {
                        display: inline-block;
                    }
                    .alert-title {
                        @include font-size(ml);
                        color: $main-s60;
                        font-family: $conden;
                        width: 100%;
                    }
                    .issue-title {
                        @include font-size(18px);
                        font-weight: 400;
                        color: $color-neutral-600;
                        font-family: $conden;
                        margin-right: 12px;
                    }
                    .issues {
                        min-width: 100px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        &.alert {
            gap: 0px !important;

            .pop-body {
                padding: 20px 0;
            }

            .message {
                ol,
                ul {
                    padding-left: 30px;
                    list-style: disc;
                }
            }
        }

        &.process {
            height: 100%;
            min-width: 60%;

            .pop-header {
                margin-top: -15px;
            }

            .pop-body {
                height: 100%;
                width: 100%;

                .kind-of {
                    @include flex-wrap(wrap);
                    height: 100%;
                    width: 100%;
                    max-height: unset;
                }
            }

            .pop-footer {
                .buttons {
                    button {
                        margin: 4px 6px 0px 6px;

                        &.inactive {
                            // background-color: red;
                            opacity: 0.3;
                        }
                    }
                }
            }

            .product-info {
                width: 100%;
                height: 25px;
                position: relative;

                .product-title {
                    @include font-size(ml);
                    font-family: $text;
                    // color: shade($accent, 50%);
                    padding-left: calc(200px - 24px);
                    display: inline-block;
                    position: absolute;
                    width: 100%;
                    background-color: $accent-dark;
                }
            }
            .resume {
                width: 200px;
                height: calc(100% - 25px);
                overflow: auto;
                padding-right: 24px;

                .resume-step {
                    height: 25%;
                    padding: 0px 12px 24px 12px;
                    border-right: 2px solid #ccc;
                    &:first-of-type {
                        margin-top: 24px;
                    }
                    &:last-of-type {
                        padding: 0px 12px 0 12px;
                        border-color: transparent !important;
                        height: calc(25% - 24px);
                    }

                    .title {
                        @include font-size(m);
                        font-family: $text;
                        text-align: right;
                        color: #777;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        transition-duration: 0.1s;

                        &:after {
                            @include border-radius(50%);
                            @include background($color: #ccc);
                            content: '';
                            width: 18px;
                            height: 18px;
                            border: 2px solid #fff;
                            position: absolute;
                            top: 0;
                            right: -22px;
                            transition-duration: 0.1s;
                        }
                    }
                    .subtitle {
                        display: block;
                        width: 100%;
                        color: #777;
                        text-align: right;
                    }

                    &.current {
                        // border-color: $accent-dark;
                        .title {
                            @include font-size(ml);

                            &:after {
                                border: none;
                                background-color: $accent-dark;
                            }
                        }
                    }
                    &.done {
                        border-color: $main;
                        .title {
                            color: $main-text;
                            &:after {
                                background-color: $main;
                            }
                        }
                    }
                }
            }
            .content {
                width: calc(100% - 200px);
                height: calc(100% - 25px);
                overflow: hidden;
                padding: 24px 24px 24px 0px;

                .step {
                    display: none;
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                }
                .step.current {
                    display: block;
                }

                .step-message {
                    @include font-size(sm);
                    color: #666;
                    text-align: center;
                    margin-top: 0;
                }
            }

            .tags {
                @include display-flex();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                height: 100%;
                width: 100%;
                padding-left: 24px;

                .tag {
                    @include border-radius(3px);
                    @include text-ellipsis();
                    @include width-fit-content();
                    @include font-size(ml);
                    text-align: center;
                    height: 50px;
                    margin: 12px 6px;
                    padding: 15px;
                    display: inline-block;
                    background-color: #eee;
                    cursor: pointer;
                    transition-duration: 0.2s;

                    &.selected {
                        background-color: $accent-t40;
                    }
                }
            }

            // .fcalendar-container {
            // }

            .fcalendar-container.p-datetime {
                .calendar,
                .time {
                    display: inline-block;
                    width: auto;
                }
                .calendar {
                    display: inline-block;
                    min-width: 300px;

                    .vfc-main-container {
                        max-width: 300px;
                    }
                }
                .time {
                    display: inline-block;
                    min-width: 150px;
                    text-align: center;

                    .message {
                        @include font-size(sm);
                        color: $main;
                        font-family: $text-bold;
                        text-align: center;
                        display: block;
                        width: 100%;
                    }

                    input {
                        @include border-radius($default-border-radius);
                        @include font-size(m);
                        color: $main;
                        font-family: $text-bold;
                        text-align: center;
                        height: 40px;
                        padding: 0 6px 0 12px;
                        background-color: $main-t80;
                        border: none;
                        display: inline-block;
                    }
                }
            }

            .input-number {
                @include display-flex();
                @include flex-direction(column);
                @include align-items();
            }

            .input-text {
                @include background($image: img('keyboard_aaaaaa.svg'), $size: 24px, $position: left 6px center);
                padding: 12px 42px;
                width: 80%;
                margin: 0 auto;

                input {
                    @include border-radius(3px);
                    @include font-size(m);
                    font-family: $text;
                    text-align: center;
                    border: 2px solid $accent-dark;
                    background-color: $accent-t90;
                    width: 100%;
                    height: 50px;
                }
            }
        }

        &.postpone {
            .kind-of {
                padding: 0;

                .postpone-info {
                    @include display-flex();
                    @include justify-content();
                    @include flex-wrap(wrap);
                    // width: calc(600px + (10px * 4));
                    width: 100%;

                    .title-date {
                        @include font-size(ml);
                        font-family: $text;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 12px;
                    }

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }
                    .step-date {
                        height: 340px;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $error;
                    }

                    .datepicker {
                        width: 200px;
                        border: none;
                        box-shadow: none;

                        .v-picker__title {
                            display: none;
                        }

                        .v-picker__body {
                            margin: 0;
                        }
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: $accent-t50;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                    }
                }

                .postpone-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
        }

        &.justify {
            .kind-of {
                padding: 6px 12px;

                .justify-info {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    @include justify-content();
                    width: calc(600px + (10px * 4));

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $error;
                        width: 100%;
                        text-align: center;

                        p {
                            color: $error;
                        }
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: $accent-t50;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                        min-height: 160px;
                    }
                }

                .justify-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
        }

        &.dateTime {
            .pop-body {
                min-width: 300px;
            }
        }

        &.disconnect {
            .pop-header {
                .title-message {
                    background-color: $error-t80;
                    color: $inactive-s90;

                    &::after {
                        border-right-color: $error-t80;
                    }
                }
            }

            .pop-body {
                @include flex-direction(column);
                margin-top: 15px;
                margin-bottom: 9px;

                &::before {
                    @include background($image: img('andy_local_logout.svg'));
                    content: '';
                    display: block;
                    width: 100%;
                    min-height: 150px;
                    margin-bottom: 12px;
                }

                .message {
                    @include font-size(m);
                }
            }

            .pop-footer {
                .act {
                    background-color: $error-t80;
                    color: $inactive-s90;
                    font-family: $text-bold;

                    &.save {
                        background-color: $error;
                        font-family: $text-bold;
                    }
                }
            }
        }

        &.delete {
            .pop-header {
                .title-message {
                    background-color: $error-t80;
                    color: $inactive-s90;

                    &::after {
                        border-right-color: $error-t80;
                    }
                }
            }

            .pop-body {
                @include flex-direction(column);
                margin-bottom: 9px;

                .message {
                    @include font-size(m);
                }
            }

            .pop-footer {
                .act {
                    background-color: $error-t80;
                    color: $inactive-s90;
                    font-family: $text-bold;

                    &.save {
                        background-color: $error;
                        font-family: $text-bold;
                    }
                }
            }
        }

        &.geolocation {
            @include border-radius(8px);

            .pop-header {
                .title-message {
                    @include border-radius(8px);
                    @include align-self(center);
                    @include justify-content(center);
                    width: 400px;
                    margin-left: 25px;

                    &:after {
                        content: '';
                        @include border-radius(8px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0;
                        height: 0;
                        border: 6px solid transparent;
                        border-right-color: inherit;
                        border-left: 0;
                        // border-bottom: 0;
                        margin-top: 0px;
                        margin-left: 0px;
                    }
                }
            }

            .pop-footer {
                .buttons {
                    height: 40px;
                    margin-right: 20px;
                    margin-bottom: 20px;

                    .act {
                        @include border-radius(8px);
                        height: 40px;
                    }
                }
            }
        }

        &.resource {
            @include border-radius(8px);
            width: fit-content;
            max-width: 80% !important;

            @media (min-width: 1024px) {
                padding: 1em;
            }

            .pop-body {
                padding: 0px !important;
                margin: 0px !important;

                .header {
                    margin-bottom: 10px;
                }
            }

            .pop-footer {
                .buttons {
                    height: 40px;
                    margin-right: 20px;
                    margin-bottom: 20px;

                    .act {
                        @include border-radius(8px);
                        height: 40px;
                    }
                }
            }
        }

        // &.confirm {
        //     @media (min-width: 768px) {
        //         padding: 1.5em 2em 2em !important;
        //     }
        // }

        &.action-plan-loss,
        &.action-plan-create {
            gap: 0px;

            @media (max-width: 767px) {
                padding-top: 24px;
            }

            @media (min-width: 767px) {
                gap: 8px;
            }

            .pop-header {
                margin-top: 0px;
                padding-top: 16px;
                position: relative !important;
                padding-left: 0px;
                padding-right: 0px;

                .title {
                    font-size: 18px;
                    font-weight: bold;
                    padding: 0px !important;
                    text-align: center;
                }
            }

            .pop-body {
                padding-top: 0px !important;
                margin-top: 0px !important;
            }

            .message {
                text-align: center;
                font-size: 16px;
                background-color: inherit !important;

                @media (max-width: 767px) {
                    padding-bottom: 16px !important;
                }
            }
        }

        &.action-plan-create {
            .pop-header {
                padding-top: 24px !important;
            }

            .pop-body {
                padding-bottom: 8px;
            }
        }
    }

    &.calm {
        .pop-alex {
            background-image: img('alex_carita_02_right.svg');
        }
    }
    &.smiling {
        .pop-alex {
            background-image: img('alex_carita_03_right.svg');
        }
    }
    &.happy {
        .pop-alex {
            background-image: img('alex_carita_12.svg');
        }
    }
    &.error {
        .pop-alex {
            background-image: img('alex_carita_04.svg');
            background-color: $error-t60;
            border: solid 3px $error;
        }

        .pop-body {
            .kind-of {
                .message {
                    background-color: $error-t60 !important;
                }
            }
        }
    }
    &.warning {
        .pop-alex {
            background-image: img('alex_carita_04.svg');
        }
    }
    &.surprised,
    &.warning {
        .pop-alex {
            background-image: img('alex_carita_05.svg');
        }
    }
    &.talking,
    &.info {
        .pop-alex {
            background-image: img('alex_carita_10.svg');
        }
    }
    &.looking_up {
        .pop-alex {
            background-image: img('alex_carita_01_right.svg');
        }
    }
    &.looking_down {
        .pop-alex {
            background-image: img('alex_carita_08_right.svg');
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.popup-container.portrait {
    @include align-items(flex-end);
    width: 100% !important;
    left: 0 !important;

    &.email-alert {
        @include align-items(center);
    }
    &.tablePopup {
        height: 100%;
        max-height: 100%;
        .popup-container.tablePopup .popup .pop-body .kind-of.check-container .check-box-container .check-box {
            width: 287px;
        }
        .popup {
            position: fixed;
            max-height: calc(100% - 16px);
            right: auto;
            left: auto;
            width: 350px;
            height: 100%;

            .pop-body {
                height: 80%;
                overflow: visible;
                margin: 0 auto;
                padding: 0 auto;
                .check-box-container {
                    background-image: img('drag_dots.svg');
                    background-repeat: no-repeat;
                    background-position: 8px;
                }
                .kind-of.check-container .check-box-container .check-box {
                    width: 287px;
                }
                div.kind-of {
                    width: 100%;
                    margin-top: 0;
                    height: 100%;
                    overflow-x: hidden;
                    padding: 0 auto;
                    h1 {
                        padding-left: 0px;
                    }
                }
            }
            .pop-footer {
                height: 20%;
                div.buttons {
                    flex-direction: column;
                    gap: 8px;
                    justify-content: flex-end;
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .popup {
        height: fit-content;
        max-width: unset;
        bottom: 10px;

        .pop-alex {
            @include background($size: rem(40px));
            width: rem(60px);
            height: rem(60px);
            top: rem(-35px);
        }

        .pop-alex-lottie {
            @include border-radius(100%);
            background-color: white;
            border: solid 3px $main-t80;
            width: rem(56px);
            height: rem(56px);
            display: block;
            position: absolute;
            top: rem(-35px);
            left: rem(15px);
            z-index: $z-popups-default + 1;
            div#app {
                height: 100%;
                display: grid;
                place-content: center;
                div {
                    height: 50%;
                    margin-top: 35%;
                    display: grid;
                    place-content: center;
                    svg {
                        width: 58px !important;
                        height: 58px !important;
                    }
                }
            }
        }
        .pop-header {
            @include font-size(s);
            position: absolute;
            top: -0.3rem;
            left: 0.1rem;
        }

        .pop-body {
            width: 100%;
            overflow: none;
            min-width: unset;
            background: none;
            height: fit-content;
            margin-top: 20px;
            padding-top: 16px;

            &::before {
                //@include background( $image: img('andy_local_logout.svg'), $size: 100px );
                background: none;
                width: 100% !important;
                overflow: hidden;
                //min-height: 150px;
                min-height: 0;
                min-width: 0;
            }

            .kind-of {
                // height: fit-content;
                height: calc(100% - 40px);
                // overflow: auto;

                .message {
                    @include font-size(s);
                    @include border-radius(4px);
                    padding: 8px;
                    color: black;
                    font-family: Source Sans 3;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &:after {
                        left: 25px;
                    }
                }
                &.item-settings {
                    .title {
                        @include font-size(sm);
                    }
                }
            }
        }

        .pop-footer {
            text-align: center;
            height: calc(100% - 40%);
            align-items: end;
            // min-height: 165px;

            .act {
                @include font-size(s);
                max-width: calc(100%);
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .buttons {
                flex-direction: column;
                gap: 0.5em;

                button {
                    @include font-size(s);
                }

                // .act {
                //     &.save {
                //         // background-color: $main-dark;
                //     }
                // }
            }
        }

        // &.date {
        //     // .v-picker.v-card.v-picker--date.v-picker--landscape {
        //     //     background-color: red;

        //     //     .v-picker__title.v-picker__title--landscape.primary {
        //     //         position: static;
        //     //         width: 100%;

        //     //         .v-date-picker-title {
        //     //             @include flex-direction(row);

        //     //             * {
        //     //                 @include font-size(sm);
        //     //             }

        //     //             br {
        //     //                 display: none;
        //     //             }
        //     //         }
        //     //     }

        //     //     .v-picker__body {
        //     //         position: static;
        //     //         margin-left: 0;
        //     //     }
        //     // }
        // }

        &.dateRange {
            height: 100%;

            .kind-of {
                display: inline-grid;
                // 1 column
                grid-template-columns: 1fr;
                width: 100%;
                min-height: auto;
                min-height: 688px;
                .vfc-styles-conditional-class {
                    height: 350px;
                }
            }
            @include portrait-tablet {
                .kind-of {
                    display: grid;
                    min-height: unset;
                    grid-template-columns: minmax(auto, 50%) 1fr;

                    .vfc-styles-conditional-class {
                        height: unset;
                    }
                }
            }
            .v-picker {
                box-shadow: none;

                .v-picker__title {
                    padding: 9px 16px;
                }

                .v-picker__title__btn {
                    color: #fff !important;
                    padding: 0;
                    margin: 0;

                    > * {
                        color: inherit;
                    }
                }
            }

            .primary {
                background-color: $main !important;
                border-color: $main !important;
            }
        }
        &.issueAlert {
            .pop-body {
                overflow: hidden;
                .kind-of {
                    justify-content: space-between;
                    p.issue-name {
                        font-weight: bold;
                    }
                }
            }
        }
        &.sign {
            .pop-body {
                .kind-of {
                    @include flex-direction(column-reverse);
                }
                .right,
                .left {
                    width: 100%;
                }
            }

            .pop-footer {
                height: 150px;
            }
        }

        &.resource {
            @include border-radius(0);
            bottom: 0 !important;
            min-width: 100%;
            height: fit-content;
            max-height: calc(100% - 60px);
            overflow: auto;

            .pop-body {
                width: 100%;
                height: 100%;
                min-height: 100%;
            }
        }

        &.select {
            gap: 0.8em;
            .list {
                height: 360px;
            }
            .pop-footer {
                min-height: 108px;
            }
        }

        &.justify {
            gap: 1em;

            .pop-body {
                width: 100%;
                padding-top: 0px;
                padding-right: 4px;
                padding-left: 4px;

                .kind-of {
                    max-width: 100%;
                    padding: 0 !important;

                    .justify-info {
                        max-width: 100%;

                        .step {
                            margin: 0;
                            width: 100%;

                            .reasons {
                                gap: 8px;
                            }

                            .item {
                                margin: 0 !important;
                            }
                        }

                        .error {
                            max-width: 100%;

                            p {
                                color: $error;
                                padding: 0px;
                                padding-bottom: 0.4em;
                                font-weight: 400;
                                font-size: 0.9rem !important;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .pop-footer {
                min-height: 108px;
            }
        }

        &.list_audits {
            .pop-body {
                margin-top: 0px;

                .list {
                    height: 366px;
                }
            }
        }
    }
}
</style>
